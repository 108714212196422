import React, { useState, useEffect, useRef} from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip,LineChart, Line, ResponsiveContainer} from 'recharts';
import { useCookies } from 'react-cookie';

//Bootstrap Components
import {Accordion, Container, Form, FormControl, Button} from 'react-bootstrap';
import { Toast, ToastContainer} from 'react-bootstrap';
import { ListGroup, Modal} from 'react-bootstrap';

//D2L Web Components
import '@brightspace-ui-labs/navigation/d2l-navigation.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-button-icon.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-main-header.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-main-footer.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-dropdown-button-icon.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-band.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/typography/typography.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';

var env;

if (JSON.stringify(process.env.REACT_APP_ENV) == JSON.stringify("development")){
  env = 'dev';
}
else {
  env = 'prod';
}
var  westdistribution = 'https://d1cxwt39sm8ti5.cloudfront.net'
// var  eastdistribution = 'https://d17rwlewlok6e.cloudfront.net'
export default function CustomizedAccordions() {

  //hardcoded values
  const green = '#2FCD25';
  const lv2clr = 'rgba(26, 26, 26, 1)';
  const updateInterval = 30000;

  //main data
  const [serviceStatusData, setServiceStatusData] = useState(false);
  const [incidentHistory, setIncidentHistory] = useState(false);
  const [displayStatus, setDisplayStatus] = useState(false);
  const displayHistoryRef = useRef({});
  const [currentDate,setCurrentDate] = useState(new Date());
  const [dateStamp, setDateStamp] = useState([]);
  const [dateStampError, setDateStampError] = useState([]);
  const[error,seterror] = useState(null);
  const [jsonError,setJsonError] = useState({});
  const [hidden, setHidden] = useState(false);
  const [jsonLoaded, setJsonLoaded] = useState(false);
  const [timeStampLoaded, setTimeStampLoaded] = useState(false);
  const [displayGraphs, setDisplayGraphs] = useState(false);
  const displayGraphsRef = useRef(null);

  //url
  var [url, setUrl] = useState([]);
  const urlVar = useRef({})
  const [urlVal, setUrlVal] = useState([]);
  const currentURL = useRef({});
  const requestedURL = useRef('');

  //Settings related
  const [darkMode, setDarkMode] = useState([]);
  const darkModeSwitch = useRef({});
  const [autoRefresh, setAutoRefresh] = useState([]);
  const autoRefreshSwitch = useRef({});
  const [cookies, setCookie] = useCookies(['statusPageUser']);

  //Error messages
  const [showNoHistoryError, setShowNoHistoryError] = useState(false);
  const [showNoURLError, setShowNoURLError] = useState(false);
  const helpDialog = useRef(false);
  const urlDialog = useRef(false);
  const historyDialog = useRef(false);
  const graphDialog = useRef(false);

  //view sizes
  const [smallView, setSmallView] = useState([]);
  const searchSmall = useRef({});
  const searchLarge = useRef({});

  const nav = useRef({});
  const fetchInterval = useRef({});
  const searchURL = () => gotoURL();

  const accordions = useRef({});
  const accordionitem = useRef({});

  const help = () => {
    helpDialog.current.opened = true;
  }

  const urlError = () => {
    urlDialog.current.opened = true;
  }

  const historyError = () => {
    historyDialog.current.opened = true;
  }

  const graphError = () => {
    graphDialog.current.opened = true;
  }

  const showCurrentStatus = () =>{
    setDisplayStatus(true);
    displayHistoryRef.current = false;
    displayGraphsRef.current = false;
  }

  const _handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      gotoURL();
    }
  }

  const _handleAccordionKeyDown = (index) => (e) => {
    if (e.key === 'Escape'){
      accordions.current[index].focus();
      accordions.current[index].click();
    }
  }

  function showHistory() {
    if (incidentHistory){
      setDisplayStatus(false);
      displayHistoryRef.current = true;
      displayGraphsRef.current = false;
    }
    else historyError();
  }

  function showGraphs() {
    if (displayGraphs) {
       setDisplayStatus(false);
       displayGraphsRef.current = true;
       displayHistoryRef.current = false;
    }
    else graphError();
   }

  function reloadPage(){
    window.location = "http://"+window.location.host;
  }

  function darkModeSCSS(bool){
    if (bool){
      document.documentElement.style.setProperty('--d2l-dropdown-background-color', '#212121');
      nav.current.style.background = 'rgba(26, 26, 26, 1)';
      nav.current.style.color = 'white';

      document.documentElement.style.setProperty('--bg-color', '#212121');
      document.documentElement.style.setProperty('--accordion-color', '#212121');
      document.documentElement.style.setProperty('--lastUpdated-color', 'white');
      document.documentElement.style.setProperty('--lvl2-color', 'rgba(26, 26, 26, 1)');
      document.documentElement.style.setProperty('--nav-bg-color', 'rgba(26, 26, 26, 1)');
      document.documentElement.style.setProperty('--navbar-brand', green);
      document.documentElement.style.setProperty('--service-name-color', 'white');
      document.documentElement.style.setProperty('--acordion-expanded-color', 'rgba(38, 37, 37, 1)');
      document.documentElement.style.setProperty('--accordion-button-hover', 'rgba(38, 37, 37, 1)');
      document.documentElement.style.setProperty('--font-color', 'white');
      document.documentElement.style.setProperty('--history-list-group', 'rgba(26, 26, 26, 1)');
      document.documentElement.style.setProperty('--main-status-color', 'white');
      document.documentElement.style.setProperty('--accordion-box-shadow', '0px 0px 50px 1px rgba(0, 0, 0, 0.5)');
    }
    else {

      document.documentElement.style.setProperty('--d2l-dropdown-background-color', '#212121');
      nav.current.style.background = 'white';
      nav.current.style.color = 'black';

      document.documentElement.style.setProperty('--bg-color', 'white');
      document.documentElement.style.setProperty('--accordion-color', '#ebebeb');
      document.documentElement.style.setProperty('--lastUpdated-color', 'black');
      document.documentElement.style.setProperty('--navbar-brand', 'white');
      document.documentElement.style.setProperty('--nav-bg-color', 'white');
      document.documentElement.style.setProperty('--accordion-box-shadow', '0px 0px 50px 1px rgba(1, 0, 0,.2)');
      document.documentElement.style.setProperty('--service-name-color', 'black');
      document.documentElement.style.setProperty('--acordion-expanded-color', 'white');
      document.documentElement.style.setProperty('--accordion-button-hover', '#C0C0C0');
      document.documentElement.style.setProperty('--font-color', 'black');
      document.documentElement.style.setProperty('--history-list-group', '#f6f6f6');
      document.documentElement.style.setProperty('--main-status-color', 'black');
    }
  }


  var fetchHeaders = new Headers();
  fetchHeaders.append('pragma', 'no-cache');
  fetchHeaders.append('cache-control', 'no-cache');


  var fetchReq = {
    method: 'GET',
    headers: fetchHeaders,
  };

  const triggerAutoRefresh = () => {
    if (autoRefresh) {
      autoRefreshSwitch.current.on = false;
      setCookie('AutoRefresh', false, { path: '/'});
      clearInterval(fetchInterval.current);
      setAutoRefresh(false);

    }
    else{
      autoRefreshSwitch.current.on = true;
      setCookie('AutoRefresh', true, { path: '/'});
      fetchInterval.current = setInterval(() => fetchData(), updateInterval);
      setAutoRefresh(true);

    }
  }

  function triggerDarkMode(){
    if (darkMode) {
      darkModeSwitch.current.on = false;
      setDarkMode(false);
      darkModeSCSS(false);
      setCookie('DarkMode', false, { path: '/'});
    }
    else{
      darkModeSwitch.current.on = true;
      setDarkMode(true);
      darkModeSCSS(true);
      setCookie('DarkMode', true, { path: '/'});
    }
  }

  async function fetchStatusJSON(env,url,distribution){
    // https://status-page-json-files.s3.us-west-1.amazonaws.com/dev/status/258hub.brightspace.com.json
    // await fetch('https://d17rwlewlok6e.cloudfront.net/'+env+'/status/'+url+'.json',fetchReq)
    await fetch(distribution+'/'+env+'/status/'+url+'.json',fetchReq)
    .then((res) => res.json())
    .then((data) => {

      if (displayHistoryRef.current !== true){
        setDisplayStatus(true)
        setServiceStatusData(data);
      }
      if (displayGraphsRef.current == true){
        setDisplayStatus(false)
        setServiceStatusData(data);
      }
    });
  }

  async function fetchStatusHistory(env,url,distribution){
    await fetch(distribution+'/'+env+'/history/'+url+'_history.json', fetchReq)
    .then((res) => res.json())
    .then((data) => {
      if (data[0].History && data[0].History.length > 0 && data[0].History[0].exists === true) {
        setIncidentHistory(data);
      }
      else {
        setIncidentHistory(false);
      }
      setDisplayGraphs(data);
      setJsonLoaded(true);
    });
  }


  async function fetchDATESTAMP(env,distribution){
    await fetch(distribution+'/'+env+'/status/'+'datestamp.json', fetchReq)
    .then((dateStampRes) => dateStampRes.json())
    .then((dateStampData) => {

      setDateStamp(dateStampData);
      setDateStampError(false);
      setTimeStampLoaded(true)
    });
  }

  function updateURL(){
    if (smallView == true){
      setUrlVal(searchSmall.current.value.replace(/^https?:\/\/|\/$/g, '').split('/')[0].toLowerCase());
      requestedURL.current = (searchSmall.current.value.replace(/^https?:\/\/|\/$/g, '').split('/')[0].toLowerCase());
    }
    else {
      setUrlVal(searchLarge.current.value.replace(/^https?:\/\/|\/$/g, '').split('/')[0].toLowerCase());
      requestedURL.current = (searchLarge.current.value.replace(/^https?:\/\/|\/$/g, '').split('/')[0].toLowerCase());
    }
  }

  async function gotoURL(){

    if (smallView) (urlVar.current = (searchSmall.current.value.replace(/^https?:\/\/|\/$/g, '')).split('/')[0].toLowerCase().trim())
    else (urlVar.current = (searchLarge.current.value.replace(/^https?:\/\/|\/$/g, '')).split('/')[0].toLowerCase().trim())
    displayHistoryRef.current = false;
    displayGraphsRef.current = false;


    try{
      await fetchStatusJSON(env,urlVar.current,westdistribution);
      setUrl(currentURL.current);
      window.history.replaceState(null, "Status Page - ", urlVar.current)
      updateURL();
    }
    catch (err) {
      urlError();

    }
    try{
      fetchDATESTAMP(env,westdistribution);
    }
    catch
    {
      setDateStampError(true);
    }

    try{
      await fetchStatusHistory(env,urlVar.current,westdistribution)
    }
    catch (err) {
      console.log("error: " + err)

    }

  }

  function fetchData() {
    if (window.location.pathname.replace(/^\//g, '') === ''){
      setUrl('global');
      urlVar.current = 'global';
    }
    else {
      setUrl(window.location.pathname.replace(/^\//g, '').toLowerCase());
      urlVar.current = (window.location.pathname.replace(/^\//g, '').toLowerCase());

      try {
        fetchStatusHistory(env,urlVar.current,westdistribution)
      }
      catch(err) {
        console.log("error: " + err)
      }

    }

    try{
      fetchDATESTAMP(env,westdistribution);
    }
    catch{
      setDateStampError(true);
    }

    try {
      fetchStatusJSON(env,urlVar.current,westdistribution);
    } catch (e) {
    }
  }

  function loadSettings(){
    if (cookies.DarkMode == 'true'){
      setDarkMode(true);
      darkModeSCSS(true);
      darkModeSwitch.current.on=true;
    }
    else if (cookies.DarkMode == null) {
      setDarkMode(false);
      darkModeSCSS(false);
      darkModeSwitch.current.on=false;
      setCookie('DarkMode', false, { path: '/'});
    }
    else if (cookies.DarkMode == 'false') {
      setDarkMode(false);
      darkModeSCSS(false);
      darkModeSwitch.current.on=false;
    }

    if (cookies.AutoRefresh == 'true'){
      autoRefreshSwitch.current.on = true;
      setAutoRefresh(true);
      fetchInterval.current = setInterval(() => fetchData(), updateInterval);
    }
    else if (cookies.AutoRefresh == null){
      autoRefreshSwitch.current.on = false;
      setAutoRefresh(false);
      fetchInterval.current = setInterval(() => fetchData(), updateInterval);
      setCookie('AutoRefresh', true, { path: '/'});
    }
    else if (cookies.AutoRefresh == 'false'){
      autoRefreshSwitch.current.on = false;
      setAutoRefresh(false);
      clearInterval(fetchInterval.current);
    }
  }

  //constantly monitor window size and ensure input URL is visibile under both conditions
  if (window.matchMedia("(min-width: 400px)").matches && window.matchMedia("(max-width: 670px)").matches){
    if (smallView !== true) {
      setSmallView(true);
      searchSmall.current.value = searchLarge.current.value;
    }
  }
  else {
    if (smallView !== false){
      setSmallView(false)
      searchLarge.current.value = searchSmall.current.value ;
    }
  }

  useEffect(async () => {

    //Determine if window is in small view mode
    if (window.matchMedia("(min-width: 0px)").matches && window.matchMedia("(max-width: 670px)").matches){
      if (smallView !== true)  setSmallView(true);
    }
    else {
      if (smallView !== false) setSmallView(false);
    }

    window.addEventListener('d2l-input-search-searched', searchURL)

    setInterval(function() {
      setCurrentDate(new Date());
    }, 1000);

    displayHistoryRef.current = false;
    displayGraphsRef.current = false;

    if (window.location.pathname.replace(/^\//g, '') === ''){
      setUrl('global');
      urlVar.current = 'global';
      currentURL.current = 'global'
      requestedURL.current = 'global'
      displayHistoryRef.current = false;
      displayGraphsRef.current = false;
    }
    else {

      currentURL.current = (window.location.pathname.replace(/^\//g, '').toLowerCase());
      urlVar.current = (window.location.pathname.replace(/^\//g, '').toLowerCase());
      requestedURL.current = (window.location.pathname.replace(/^\//g, '').toLowerCase());
      setUrl(window.location.pathname.replace(/^\//g, '').toLowerCase());

    }

    try {
      await fetchStatusJSON(env,currentURL.current,westdistribution);
      loadSettings();
    }
    catch(e) {
      setJsonError(true);
        urlDialog.current.opened = true;
        loadSettings();
    }
    try{
      fetchDATESTAMP(env,westdistribution);
    }
    catch{
      setDateStampError(true);
    }

    if (urlVar.current !== 'global'){
      try {
        fetchStatusHistory(env,window.location.pathname.replace(/^\//g, '').toLowerCase(),westdistribution)
      } catch (err)  {
      }
    }

    return () => {
      window.removeEventListner('d2l-input-search-searched', searchURL);
      clearInterval(fetchInterval.current);
    }
  }, []);

  if (error) {
    return <div class="dark-div">There were issues loading in status data.  Please contact your system administrator and provide them with the following error message: {error.message}</div>;
  }
  else if (!serviceStatusData &&  jsonError !==true ) {
    return <div class="d-flex justify-content-center dark-div">
    <div class="spinner-border dark-div" role="status">
    </div>
    </div>;
  } else if (serviceStatusData || jsonError == true) {
    return (
      <React.Fragment>
      <div class="pageDiv">
      <d2l-navigation ref={nav} style={(serviceStatusData.status === "All Services Operational" ) ? {'--d2l-branding-primary-color': green} : (serviceStatusData.status === "Degraded Experience" ) ? {'--d2l-branding-primary-color': 'orange'} : (jsonError == true)? {'--d2l-branding-primary-color': 'blue'} : {'--d2l-branding-primary-color': 'red'}  }>

      <d2l-navigation-main-header>
      <div slot="left" class="d2l-navigation-header-left">

      <h1  class="d2lServiceStatus d2l-heading-1" onClick={reloadPage} style={{cursor: 'pointer'}}>D2L Status</h1>
      </div>
      <div slot="right" class="d2l-navigation-header-right">

      <div class="searchInputDiv">
      <d2l-input-search placeholder="Brightspace URL" no-clear ref={searchLarge} id="search" label="Brightspace URL"   onKeyDown ={_handleSearchKeyDown} >
      </d2l-input-search>
      { serviceStatusData.status !== "All Services Operational" && (requestedURL.current ==='global' || requestedURL.current === '')  ? <h6 style={{ color: 'red' }}class ="Pclassleft"> See if you are affected</h6> : <p class="searchInputDiv"> </p>
      }
      </div>
      <div class="searchIconDiv" style={{height: '100%'}}>
      <d2l-navigation-dropdown-button-icon text='Search for Brightspace URL' icon="tier3:search">

      { darkModeSwitch.current.on == true  ?
        <d2l-dropdown-content theme="dark" style={{top: '80px'}}>
        <d2l-input-search placeholder="Brightspace URL"  ref={searchSmall} no-clear id="search" label="Brightspace URL"   onKeyDown ={_handleSearchKeyDown} >
        </d2l-input-search>  </d2l-dropdown-content>
        : <d2l-dropdown-content style={{top: '80px'}}>
        <d2l-input-search placeholder="Brightspace URL" ref={searchSmall}  no-clear id="search" label="Brightspace URL"   onKeyDown ={_handleSearchKeyDown} >
        </d2l-input-search>
        </d2l-dropdown-content>
      }

      </d2l-navigation-dropdown-button-icon>
      </div>

      <div class="firstIconDiv" style={{height: '100%'}}>
      <d2l-navigation-dropdown-button-icon text='Settings' icon="tier3:gear">

      { darkModeSwitch.current.on == true  ?
        <d2l-dropdown-content theme="dark" style={{top: '80px'}}>

        <d2l-switch ref={autoRefreshSwitch} theme="dark" onClick={triggerAutoRefresh}  text="Auto Refresh"></d2l-switch>

        <br/>

        <d2l-switch ref={darkModeSwitch} theme="dark" onClick={triggerDarkMode} aria-label='Dark Mode; Menu setting 2 of 2' text="Dark Mode"></d2l-switch>

        </d2l-dropdown-content>
        : <d2l-dropdown-content style={{top: '80px'}}>

        <d2l-switch ref={autoRefreshSwitch} onClick={triggerAutoRefresh} aria-label='Auto Refresh; Menu setting 1 of 2' text="Auto Refresh"></d2l-switch>
        <br/>
        <d2l-switch ref={darkModeSwitch} onClick={triggerDarkMode} aria-label='Dark Mode; Menu setting 2 of 2' text="Dark Mode"></d2l-switch>

        </d2l-dropdown-content>
      }

        </d2l-navigation-dropdown-button-icon>
      </div>

      <d2l-navigation-button-icon onClick={help} text='Help' text-hidden icon="tier3:help"></d2l-navigation-button-icon>

      </div>
      </d2l-navigation-main-header>
      <d2l-navigation-main-footer>
      <div slot="main" class="d2l-navigation-s-main-wrapper"> {(requestedURL.current !== 'global' && jsonError !==true)  ? <span class="currentUrlSpan"><span style={{fontWeight: 'bold'}}>Viewing Status Page For:</span> {requestedURL.current}</span> : null}</div>
      </d2l-navigation-main-footer>
      </d2l-navigation>

      <div class="mainDiv"><div class="clientNavDiv">
      { ((requestedURL.current  !== 'global') && (displayHistoryRef.current) && jsonError !== true && jsonLoaded == true) ?
      <d2l-button primary="true"  icon="tier3:chevron-left-circle" onClick={showCurrentStatus} description="View Current Status">View Current Status</d2l-button>

      : ((requestedURL.current  !== 'global') && (!displayHistoryRef.current) && jsonError !== true && jsonLoaded == true) ?
      <d2l-button primary="true" class="spaced-button"  icon="tier3:chevron-right-circle"   icon-right="true" onClick={showHistory} description="View Incident History" >View Incident History (Last 90 days)</d2l-button>

      : null }
       { ((requestedURL.current  !== 'global') && (displayGraphsRef.current) && jsonError !== true && jsonLoaded == true) ?
        <d2l-button primary="true"  icon="tier3:chevron-left-circle" onClick={showCurrentStatus} description="View Current Status">View Current Status</d2l-button>
      : ((requestedURL.current  !== 'global') && (!displayGraphsRef.current) && jsonError !== true && jsonLoaded == true) ?
      <d2l-button primary="true" class="spaced-button"  icon="tier3:chevron-right-circle"   icon-right="true" onClick={showGraphs} description="View Availability Graphs">View Availability Graph (Last 90 days)</d2l-button>

      : null }
      </div>
      <br/>
      {serviceStatusData && displayStatus  && <DisplayStatusPageInfo serviceStatusData={serviceStatusData} currentDate={currentDate} green={green} darkModeSwitch={darkModeSwitch} dateStamp={dateStamp} _handleAccordionKeyDown={_handleAccordionKeyDown} accordions={accordions} accordionitem={accordionitem} incidentHistory={incidentHistory} requestedURL={requestedURL} timeStampLoaded={timeStampLoaded} />}
      {incidentHistory && displayHistoryRef.current && <DisplayStatusPageHistory incidentHistory={incidentHistory} currentDate={currentDate} darkModeSwitch={darkModeSwitch}/>}
      {displayGraphs && displayGraphsRef.current && <DisplayGraph displayGraphs={displayGraphs} darkModeSwitch={darkModeSwitch} presentURL = {requestedURL.current}/>}
      </div>
      <d2l-dialog ref = {helpDialog}  id="helpDialog" title-text="D2L Service Status Page Help">
      <div>  <p tabindex="0">Welcome to the D2L Service Status Page.  The purpose of this page is to provide our clients and end users with up to date operational status of our services.</p>

      <p>Our services are divided up into different categories.  You may click on each category to view it's associated services and the respective status.</p>

      <p>There are 3 different types of statuses. "Operational" simply indicates that the service is up and running without any known issues or reported issues.
      Service Disruption" refers to impact which is rendering the service unusable. "Degraded Experience" refers to impact which is causing the service to not be functioning optimally.</p>
      <p>Given service disruption or events may not impact all clients, you may enter your specific Brightspace URL to view your specific organization's service status.  If you are not sure what your Brightspace URL is, you may use <a href="https://login-finder.d2l.com">the D2L automated chat feature</a> or contact your administrators or account manager. If a Brightspace URL is not entered, then our global status page is shown by default, which is representative of all our clients.</p>
      <p>Note: the status of these services are not a direct uptime monitor of the service. There may be a short delay before the status page reflects a service disruption.</p></div>
      <d2l-button slot="footer" data-dialog-action >Close</d2l-button>
      </d2l-dialog>

      <d2l-dialog ref = {urlDialog} id="urlDialog" title-text="URL Error Message">
      <div><p>There are no organizations found for the URL provided.  Please double check the provided URL and try again.  If the issue persists, please contact your system administrator or our support team.</p></div>
      <d2l-button slot="footer" data-dialog-action >Close</d2l-button>
      </d2l-dialog>

      <d2l-dialog ref = {historyDialog}  id="historyDialog" title-text="History Error Message">
      <div>  <p>There are no reported outages for your organization over the last 90 days.</p></div>
      <d2l-button slot="footer" data-dialog-action >Close</d2l-button>
      </d2l-dialog>
      <d2l-dialog ref = {graphDialog}  id="graphDialog" title-text="Graph Error Message">
      <div>  <p>There are no graphs available  for your organization over the last 90 days.</p></div>
      <d2l-button slot="footer" data-dialog-action >Close</d2l-button>
      </d2l-dialog>
      <div class="pageDivbottom" >
        {(darkModeSwitch.current.on !== true) ? <a class="element1" style={{ fontSize: '1.5rem', color: 'black' }} href="https://www.d2l.com/security/" target="_blank">Security</a>
          : (darkModeSwitch.current.on === true) ? <a class="element1" style={{ fontSize: '1.5rem', color: 'white' }} href="https://www.d2l.com/security/" target="_blank">Security</a> : null}
        {(darkModeSwitch.current.on !== true) ? <a class="element2" style={{ fontSize: '1.5rem', color: 'black' }} href="https://www.d2l.com/cloud/" target="_blank">Cloud</a>
          : (darkModeSwitch.current.on === true) ? <a class="element2" style={{ fontSize: '1.5rem', color: 'white' }} href="https://www.d2l.com/cloud/" target="_blank">Cloud</a> : null}
      </div>
      </div>

   </React.Fragment>
  )};
}

function DisplayStatusPageInfo ({serviceStatusData, currentDate,green, darkModeSwitch, dateStamp,_handleAccordionKeyDown,accordions,accordionitem,incidentHistory,requestedURL,timeStampLoaded}) {
  var updatedSeconds = Math.floor((currentDate.getTime()-new Date(dateStamp.dateStamp).getTime())/1000);
  return (

    <React.Fragment>

    {(serviceStatusData.status === "All Services Operational" ) ?

    <h2 class="d2l-heading-2" id="alert-heading" style={(darkModeSwitch.current.on == true) ? {color: 'white',  textAlign: 'center'} : {color:'black',  textAlign: 'center'}}>{serviceStatusData.status} <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={green} class="bi bi-check-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
    </svg></h2>

    : (serviceStatusData.status === "Degraded Experience") ?
    <h2 class="d2l-heading-2" id="alert-heading" style={(darkModeSwitch.current.on == true) ? {color: 'white',  textAlign: 'center'} : {color:'black',  textAlign: 'center'}}>Degraded Experience <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="orange" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
    </svg>{requestedURL.current ==='global' || requestedURL.current === ''  ?
    <h6 style={{ color: 'red' }}>To find out if your LMS is affected, enter the Brightspace URL in search bar.</h6>
    : <h6 class="lastUpdatedP" style={{color: 'red',textAlign: 'center'}}>{incidentHistory[0] && incidentHistory[0].updates && (incidentHistory[0].updates[0].note)} </h6>}</h2>

    :
    <h2 class="d2l-heading-2" id="alert-heading" style={(darkModeSwitch.current.on == true) ? {color: 'white',  textAlign: 'center'} : {color:'black',  textAlign: 'center'}}>Service Disruption <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>{requestedURL.current ==='global' || requestedURL.current === ''  ?
    <h6 style={{ color: 'red' }}>To find out if your LMS is affected, enter the Brightspace URL in search bar.</h6>
    : <h6 class="lastUpdatedP" style={{color: 'red',textAlign: 'center'}}>{incidentHistory[0] && incidentHistory[0].updates && (incidentHistory[0].updates[0].note)} </h6>}</h2>}
    { timeStampLoaded == true ? <p class="lastUpdatedP" style={{textAlign: 'center'}}> Last updated {updatedSeconds == 0 ? <span class="updatedTimeSpan">just now.</span> : updatedSeconds ==1 ? <span class="updatedTimeSpan">{updatedSeconds} second ago.</span> : <span class="updatedTimeSpan">{updatedSeconds} seconds ago.</span>}</p> : null}
    <br/>
    <div class="row accordionDiv">
    {serviceStatusData.categories.map((category,categoryIndex) => (
      <Accordion ref={(ref) => accordionitem.current[categoryIndex] = ref} style={{padding: '0rem 0rem'}} defaultActiveKey="0" key={category.category} >
      <Accordion.Item    eventKey={categoryIndex} key={category.category+'item'} >
      <Accordion.Button  ref={(ref) => accordions.current[categoryIndex] = ref} aria-label={'category ' + (categoryIndex+1) + 'of ' +serviceStatusData.categories.length +';' + category.category + ';' + 'status is '+category.status  } aria-controls="serviceNames" key = {category.category+'header'}>
      <div class="serviceNames column inline-block" style={{fontSize: "20px"}}>
      <span class="serviceName column inline-block" text="test">
      <div class='dot-div'>
      <span class='dot' style={(category.status === "Operational") ? {"--statusColor": green} : (category.status === "Degraded Experience") ? {"--statusColor": 'orange'} : {"--statusColor": 'red'}}>
      </span>
      </div>
      {category.category}
      </span>
      </div>
      <div class="serviceStates1 column inline-block">
      {(category.status === "Operational") ?
      <d2l-status-indicator state="success" text="Operational" style={{fontSize: "12px"}}></d2l-status-indicator>

      :(category.status === "Degraded Experience") ?
      <d2l-status-indicator state="none" text="Degraded Experience" style={{fontSize: "12px", color: "orange", borderColor: "orange"}}></d2l-status-indicator>

      :
      <d2l-status-indicator state="alert" text="Service Disruption" style={{fontSize: "12px"}}></d2l-status-indicator>}

      </div>

      </Accordion.Button>
      <Accordion.Body id = {category.category}>
      <div class="status" >
      {category.products.map((product,productIndex) => (
        <Accordion style={{padding: '0rem 0rem'}}>
        <Accordion.Item   eventKey={productIndex}>
        <Accordion.Button aria-label={category.category + 'category; ' + ' product ' + (productIndex+1) + 'of ' + category.products.length + product.service + ' status is '+product.eventType  }  onKeyDown ={_handleAccordionKeyDown(categoryIndex)} >
        <div class="accordionButtonDiv row inline-block"  style={(product.eventType === "Operational") ? {"--statusColor": green} : (product.eventType === "Degraded Experience") ? {"--statusColor": 'orange'} : {"--statusColor": 'red'}}>
        <div class="serviceNames column inline-block">
        <div class='dot-div'>
        <span class='dot'>
        </span>
        </div>
        <span class='serviceName'>
        {product.service}
        </span>
        </div>
        <div class="serviceStates column inline-block"> {(product.eventType === "Operational" ) ?   <d2l-status-indicator state="success" text="Operational" style={{fontSize: "12px"}}></d2l-status-indicator>
        : (product.eventType === "Degraded Experience")   ?   <d2l-status-indicator state="none" text="Degraded Experience" style={{fontSize: "12px", color: "orange", borderColor: "orange"}}></d2l-status-indicator>
        :     <d2l-status-indicator state="alert" text="Service Disruption" style={{fontSize: "12px"}}></d2l-status-indicator>
        }</div></div></Accordion.Button>
        <Accordion.Body>
        <div class="content">
        <p>{product.impact}</p>
        </div>
        </Accordion.Body>
        </Accordion.Item>
        </Accordion>
      ))}
    </div>
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
    ))}</div>
     <br/>
    </React.Fragment>
  );
}
function DisplayStatusPageHistory ({incidentHistory}) {
  return (
    <React.Fragment>
    <div class="timeline"><ul>
    {incidentHistory.map(incident => (
      incident.History.map(historyItem => (
      <li>
      <h2 class="historyDate">Approximate Impact Start Time: {historyItem.dateCreated}&nbsp;</h2>
      <span style={{display: 'inline'}}>&nbsp;{historyItem.status}</span>
      <div class="content">
      {historyItem.endDate !== '' ? <p className="historyEndDate">Approximate Resolution Time: {historyItem.endDate}</p> : <p className="historyEndDate">Still Going On</p>}
      <h3 class="historyImpactedProductsLabel">Summary</h3>
      <ListGroup>
      {historyItem.impactedProducts.map(product => (
        <ListGroup.Item>
        <h4>{product.productName}</h4>
        <p>{product.productDesc}</p>
        </ListGroup.Item>
      ))}
      </ListGroup>
      <br/>
      <h3 class="historyUpdateLabel">Latest Update</h3>
      <ListGroup>
      {historyItem.updates.slice(0, 1).map(update => (<>
        <ListGroup.Item>
        <div class="row" style={{width: '100%'}}>
        <h4 class="historyUpdateNote">{(update.dateUpdated)} - {update.note}</h4>
        </div>
        </ListGroup.Item><br/></>
      ))}</ListGroup>
      </div>
      </li>
       ))
    ))}</ul></div></React.Fragment>
  )
}

function DisplayGraph({ displayGraphs,darkModeSwitch, presentURL }) {
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0)
  const [newMargin, setMargin] = useState(0)
  const [zoom, setZoom] = useState(0)

  useEffect(() => {
    function updateChartSize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const newWidth = windowWidth * 0.85;
      const newHeight = windowHeight * 0.5;
      setChartWidth(newWidth);
      setChartHeight(newHeight);
      const zoomLevel = window.innerWidth / window.outerWidth;
      setZoom(zoomLevel)
      const newMargin = { top: 50 * zoomLevel, right: 20 * zoomLevel, left: 20 * zoomLevel, bottom: 50 * zoomLevel };
      setMargin(newMargin);
      const container = document.querySelector('.mainDiv1');
    }
    updateChartSize();
    window.addEventListener('resize', updateChartSize);
    return () => window.removeEventListener('resize', updateChartSize);
  }, []);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
       const date = payload[0].payload.date;
       const availability = payload[0].value;

       return (
         <div style={{ backgroundColor: 'white', color: 'black', padding: '10px' }}>
           <p>{date}</p>
           <p>Availability: {availability}</p>
         </div>
       );
    }

    return null;
   };
  const chartRef1 = useRef(null); // Ref for daily graph
  const chartRef2 = useRef(null); // Ref for monthly graph
  const today = new Date();

  // Extract DailyGraphData and MonthlyGraphData
  const { DailyGraphData, MonthlyGraphData } = displayGraphs[0];

  const dailyData = Array.from({ length: 89 }, (_, index) => {
    const date = new Date(today);
    date.setDate(today.getDate() - index);
    const dateString = date.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"

    // Find the corresponding value in DailyGraphData or use 100 as default
    const dailyValue = DailyGraphData.find(data => Date.parse(data.date) === Date.parse(dateString)) || { value: 100 };
    return { date: dateString, Availability: parseFloat(dailyValue.value) };
   });
   const monthlyData = Array.from({ length: 4 }, (_, index) => {
    const month = new Date(today);
    month.setMonth(today.getMonth() - index);
    const monthString = month.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }); // Generate month string

    // Adjust the format of monthString to match the format in MonthlyGraphData
    const formattedMonthString = `${month.getFullYear()}-${month.getMonth() + 1 < 10 ? '0' : ''}${month.getMonth() + 1}`;

    // Find the corresponding value in MonthlyGraphData or use 100 as default
    const monthlyValue = MonthlyGraphData.find(data => data.date === formattedMonthString) || { value: 100 };
    return { date: monthString, Availability: parseFloat(monthlyValue.value), OutageDetails: monthlyValue.OutageDetails };
   });

  // Sort data by date in ascending order
  dailyData.sort((b, a) => new Date(b.date) - new Date(a.date));
  monthlyData.sort((b, a) => new Date(b.date) - new Date(a.date));

  const handleDownloadSVG = (chartRef,graphType) => {
    const fileName = graphType === 'monthly' ? `${presentURL}_monthly_graph.jpg` : `${presentURL}_daily_graph.jpg`;
    const chartElement = chartRef.current;
    const svgElement = chartElement.querySelector('.recharts-wrapper').querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.fillStyle = darkModeSwitch.current.on ? '#212121' : '#FFFFFF';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0);
      const jpegData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = jpegData;
      link.download = fileName
      link.click();
    };
    image.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };
  const handleDownloadCSV = (data,graphType) => {
    const fileName = graphType === 'monthly' ? `${presentURL}_monthly_data.csv` : `${presentURL}_daily_data.csv`;
    const csvContent = "data:text/csv;charset=utf-8," +
      data.map(e => Object.values(e).join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };
  function RequestMoreData() {
    const url = "https://community.d2l.com/brightspace/categories/support?page=case-create&sr=Site%20Availability%20Report"
    window.open(url, "_blank", "noreferrer");
   }
   // Generating OutageDetails
   const processOutageDetails = (monthlyData) => {
    return monthlyData.flatMap((item) => {
      if (!item.OutageDetails) return []; // If there are no OutageDetails, return an empty array

      return item.OutageDetails.split('~') // Split the OutageDetails string by '~'
        .map(detail => {
          const [duration, times] = detail.split(': '); // Split each detail by ': ' to get duration and times
          const [startTime, endTime] = times.split(' to '); // Split times by ' to ' to get start and end times

          return {
            Duration: duration, // Set the Duration property
            StartDate: startTime, // Set the StartDate property
            EndDate: endTime // Set the EndDate property
          };
        })
        .filter(detail => parseInt(detail.Duration) > 0); // Filter out details with a Duration of 0 or less
    });
  };
  const tableData = processOutageDetails(monthlyData).sort((b, a) => new Date(a.StartDate) - new Date(b.StartDate));

  return (
    <React.Fragment>
      {/* Daily Graph */}
      <div className="noteDiv">
        <h6 style={{fontSize: `15px`,fontWeight: 'bold',color: darkModeSwitch.current.on ? "white" : "black"}}>Note: Availability data may take up to 2 business days to be entered.</h6>
      </div>
      <br/>
      {tableData.length > 0 ? (
        <div className="table-container">
          <div className={darkModeSwitch.current.on ? "table-heading-dark" : "table-heading"}>Outage Details for {presentURL}</div>
          <table className={darkModeSwitch.current.on ? "table-dark" : "table"}>
            <thead>
              <tr>
                <th>Duration</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr key={item.Duration}>
                  <td>{item.Duration}</td>
                  <td>{item.StartDate}</td>
                  <td>{item.EndDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ): (
      <div className="noteDiv">
        <div className="rainbow-text">No outages in the last 4 months <span>🎉</span></div>
      </div>
    )}  
      <div ref={chartRef1} className="mainDiv1">
        <div className={darkModeSwitch.current.on ? "chartWrapper1" : "chartWrapper"}>
        <div className="downloadButtons">
          <div onClick={() => handleDownloadSVG(chartRef1, 'daily')} className={darkModeSwitch.current.on ? "downloadButton" : "downloadButtonDark"} title="Download graph for last 90 days">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={darkModeSwitch.current.on ? "currentColor" : "white"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17 8l-5 5-5-5"></path>
                <path d="M12 15V3"></path>
              </svg>
            </div>
            <div onClick={() => handleDownloadCSV(dailyData, 'daily')} className={darkModeSwitch.current.on ? "downloadCSVButton" : "downloadCSVButtonDark"} title="Download CSV of last 90 days" style={{ color: darkModeSwitch.current.on ? "black" : "white" }}>
              CSV
            </div>
            <a href="#" onClick={(e) => { e.preventDefault(); RequestMoreData();}} className={darkModeSwitch.current.on ? "requestButton" : "requestButtonDark"} title="Request More than 90 days Of Data" style={{ color: darkModeSwitch.current.on ? "#00FF00" : "#0474c4" }}>
              Need Older Data?
              </a>
          </div>
          <LineChart
            width={chartWidth}
            height={chartHeight}
            data={dailyData}
            margin={newMargin}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" domain={[0, displayGraphs.length - 1]} />
            <YAxis type="number" domain={[0, 100]} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="Availability" stroke={darkModeSwitch.current.on ? "#00FF00" : "#0474c4"} />
            <text x="50%" y={15} fill={darkModeSwitch.current.on ? '#00FF00' : '#0474c4'} style={{  fontSize: `15px`, fontWeight: 'bold', textAnchor: 'middle', zindex: 10}}>Availability for {presentURL} from past 90 days</text>
          </LineChart>
        </div>
      </div>

      {/* Monthly Graph */}
      <div ref={chartRef2} className="mainDiv1">
        <div className={darkModeSwitch.current.on ? "chartWrapper1" : "chartWrapper"}>
          <div className="downloadButtons">
            <div onClick={() => handleDownloadSVG(chartRef2, 'monthly')} className={darkModeSwitch.current.on ? "downloadButton" : "downloadButtonDark"} title="Download graph for past 4 months">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={darkModeSwitch.current.on ? "currentColor" : "white"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17 8l-5 5-5-5"></path>
                <path d="M12 15V3"></path>
              </svg>
            </div>
            <div onClick={() => handleDownloadCSV(monthlyData, 'monthly')} className={darkModeSwitch.current.on ? "downloadCSVButton" : "downloadCSVButtonDark"} title="Download CSV of past 4 months" style={{ color: darkModeSwitch.current.on ? "black" : "white" }}>
              CSV
            </div>
            <a href="#" onClick={(e) => { e.preventDefault(); RequestMoreData();}} className={darkModeSwitch.current.on ? "requestButton" : "requestButtonDark"} title="Request More than 90 days Of Data" style={{ color: darkModeSwitch.current.on ? "#00FF00" : "#0474c4" }}>
              Need Older Data?
              </a>
          </div>
          <LineChart
            width={chartWidth}
            height={chartHeight}
            data={monthlyData}
            margin={newMargin}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis type="number" domain={[0, 100]} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="Availability" stroke={darkModeSwitch.current.on ? "#00FF00" : "#0474c4"} />
            <text x="50%" y={15} fill={darkModeSwitch.current.on ? '#00FF00' : '#0474c4'} style={{ fontSize: `15px`, fontWeight: 'bold', textAnchor: 'middle' }}>Availability for {presentURL} from past 4 months</text>
          </LineChart>
        </div>
      </div>
    </React.Fragment>
  );
}

